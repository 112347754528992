import './App.css';
import { Suspense, lazy } from 'react';
import PrivateRoute from './PrivateRoute';
import BaseLayout from './BaseLayout';
import AnonymousLayout from './AnonymousLayout';
import SignIn from './components/AccessControl/SignIn';
import { RouterProvider, createHashRouter, Link } from 'react-router-dom';
import { Spin } from 'antd';
import DailyUsagePieChart from './components/Overview/DailyUsagePieChart';
import AutoLoginLayout from './AutoLoginLayout';

const OverviewLazy = lazy(() => import('./components/Overview/Overview'));
const ReportLazy = lazy(() => import('./components/Report/Report'));

const items = [
  {
    key: 0,
    label: <Link to='/' >ภาพรวม</Link>,
  },
  {
    key: 1,
    label: <Link to='/report' >รายงาน</Link>,
  },
];

const router = createHashRouter(
  [
    {
      path: '/',
      element: application.username ? <BaseLayout items={items} /> : <AnonymousLayout />,
      children: [
        {
          path: 'sign-in',
          Component: SignIn,
        },
        {
          index: true,
          element: <PrivateRoute><Suspense fallback={<Spin size="large" />}><OverviewLazy /></Suspense></PrivateRoute>,
        },
        {
          path: 'report',
          element: <PrivateRoute><Suspense fallback={<Spin size="large" />}><ReportLazy /></Suspense></PrivateRoute>,
        },
        {
          path: 'chart',
          element: <DailyUsagePieChart egatProducePower={100} solarProducePower={250} />,
        },
      ],
    },
    {
      path: '/dashboard',
      element: <AutoLoginLayout />,
      children: [
        {
          index: true,
          Component: OverviewLazy,
        }
      ],
    },
  ]
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

