import { ReactNode }  from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
    children?: ReactNode
}

function PrivateRoute({ children, ...props }: Props) {
    return application?.username ? <>{children}</> : <Navigate to="/sign-in" />;
}

export default PrivateRoute