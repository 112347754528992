import React, { useEffect, useRef, useState } from "react";
import ReactEcharts, { EChartsOption } from "echarts-for-react";
import { formatNumber } from "../../utils/Number";
import electricityUsageTodayThunderboltLogo from '../../assets/electricityUsageTodayThunderboltLogo.png'

type Props = {
    egatProducePower: number,
    solarProducePower: number,
};

const DailyUsagePieChartComponent: React.FC<Props> = (props: Props) => {
    const [ratio, setRatio] = useState(1);
    const ref = useRef<ReactEcharts>(null);
    useEffect(() => {
        if (ref.current) {
            setRatio(ref.current.ele.clientWidth / 230);
        }
    }, [ref, setRatio])

    const option: EChartsOption = {
        title: {
            text: `{spacer|}\n{Electric|}\n{spacer| }{text|\n${formatNumber(props.egatProducePower + props.solarProducePower)}} \n พลังงงาน\n ที่ใช้ไป\n {unit|(kWh)}`,
            left: 'center',
            top: 'center',
            textStyle: {
                color: '#ffffff',
                fontSize: 14 * ratio,
                fontFamily: 'Prompt-Light',
                fontWeight: 500,
                rich: {
                    text: {
                        fontSize: 20 * ratio,
                        color: '#ffffff',
                        fontFamily: 'Prompt-Bold',
                    },
                    Electric: {
                        padding: [4, 0, 5, 0],
                        backgroundColor: {
                            image: electricityUsageTodayThunderboltLogo,
                        }
                    },
                    spacer: {
                        height: 3 * ratio,
                    },
                    unit: {
                        color: '#FFFFFF',
                        fontSize: 9 * ratio,
                        fontFamily: 'Prompt',
                    }

                },
            },
        },
        tooltip: {
            trigger: 'item'
        },

        series: [
            {
                name: 'ปริมาณการใช้ไฟฟ้า',
                type: 'pie',
                radius: ['45%', '70%'],
                itemStyle: {
                    borderRadius: 0,
                    borderColor: '#313843',
                    borderWidth: 5
                },
                label: {
                    show: false,
                    formatter(param: any) {
                        // correct the percentage
                        return param.name + ' (' + param.percent! * 2 + '%)';
                    },
                },
                color: ['#add136', '#faa61a'],
                data: [

                    { value: props.solarProducePower, name: 'ไฟฟ้าจากแสงอาทิตย์' },
                    { value: props.egatProducePower, name: 'ไฟฟ้าซื้อจากภาครัฐ' },
                ]
            }
        ],
        backgroundColor: 'transparent',
    };
    return <ReactEcharts ref={ref} option={option} theme="dark" />;
};
const DailyUsagePieChart = DailyUsagePieChartComponent;
export default DailyUsagePieChart;