import './index.css';
import { SWRConfig } from 'swr';
import ReactDOM from 'react-dom/client';
import { ConfigProvider, ThemeConfig } from 'antd';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PlantProvider } from './contexts/PlantContext';
import { sessionStorageProvider } from './utils/Storage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme: ThemeConfig = {
  components: {
    Card: {
      colorBgContainer: '#313843',
      colorText: '#fff',
      colorTextDescription: '#fff',
      colorTextHeading: '#fff',
      colorBorderSecondary: 'transparent',
      borderRadiusLG: 2,
    },
    DatePicker: {
      colorBgContainer: '#313843',
      colorPrimaryActive: '#fff',
      activeBorderColor: '#fff',
      hoverBorderColor: '#fff',
    },
    Form: {
      colorTextLabel: '#fff',
      labelColor: '#fff',
    },
    Layout: {
      bodyBg: '#141b28',
      headerBg: '#313843',
      headerColor: '#313843'
    },
    Menu: {
      darkItemBg: '#313843',
    },
    Radio: {
      colorText: '#fff',
    },
    Select: {
      colorBgContainer: '#313843',
      colorPrimary: '#fff',
      colorPrimaryHover: '#fff',
    },
    Typography: {
      colorTextHeading: '#fff',
    },
  },
};
root.render(
  <PlantProvider>
    <ConfigProvider
      theme={theme}
    >
      <SWRConfig value={{ provider: sessionStorageProvider }}>
        <App />
      </SWRConfig>
    </ConfigProvider>
  </PlantProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
