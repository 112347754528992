export const sessionStorageProvider = () => {
    const expiredDate = new Date(parseInt(sessionStorage.getItem('app-cache-expired') || '', 10));
    let storage = sessionStorage.getItem('app-cache');
    if (new Date() > expiredDate) {
        storage = null;
        sessionStorage.removeItem('app-cache-expired');
    }

    // When initializing, we restore the data from `sessionStorage` into a map.
    const map = new Map<string, any>(JSON.parse(storage || '[]'));

    // Before unloading the app, we write back all the data into `sessionStorage`.
    window.addEventListener('beforeunload', () => {
        const appCache = JSON.stringify(Array.from(map.entries()));
        sessionStorage.setItem('app-cache', appCache);

        if(!sessionStorage.getItem('app-cache-expired')) {
            let date = new Date();
            date.setMinutes(date.getMinutes() + 10);
            sessionStorage.setItem('app-cache-expired', date.getTime().toString());
        }
    })

    // We still use the map for write & read for performance.
    return map;
}