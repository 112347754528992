import axios from 'axios';

axios.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  return config;

});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 403) {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/controllers/sign-out.php`, {}, { withCredentials: true });
      window.location.href = `${application.webRoot}`;
    }
    return Promise.reject(error);
  });

export const httpClient = axios