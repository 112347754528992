import { AxiosResponse } from "axios";
import { Device, DeviceInformation, ResidentDeviceProducePower, PowerProduce, DeviceProducePower, Alarm } from "../types/DeviceData";
import { httpClient } from "../utils/HttpClient";

const getRealtimeDeviceProducePower = (deviceList: Device[]): Promise<AxiosResponse<ResidentDeviceProducePower>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        deviceList: deviceList.map(device => ({
            ...device,
            id: device.id.toString(),
        }))
    }));
    return httpClient.post<ResidentDeviceProducePower>(`${process.env.REACT_APP_BACKEND_URL}/controllers/realtime-device-produce-power.php`, form);
}

const getPowerProduce = (solarSnsList: string[], timeSeries: string): Promise<AxiosResponse<PowerProduce>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        solarSnsList,
        timeSeries
    }));
    return httpClient.post<PowerProduce>(`${process.env.REACT_APP_BACKEND_URL}/controllers/power-produce.php`, form);
}

const getDeviceProducePower = (solarSnsList: string[], timeSeries: string): Promise<AxiosResponse<DeviceInformation[]>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        solarSnsList,
        timeSeries
    }));
    return httpClient.post<DeviceInformation[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/devices-power-produce.php`, form);
}

const getDeviceList = (stationCodes: string): Promise<AxiosResponse<Device[]>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        stationCodes
    }));
    return httpClient.post<Device[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/device-list.php`, form);
}

export const fetchDeviceList = ({ key, stationCodes }: { key: string, stationCodes: string }): Promise<Device[]> => {
    return getDeviceList(stationCodes).then(res => res.data);
}

const getAlarmList = (stationCodes: string): Promise<AxiosResponse<Alarm[]>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        stationCodes
    }));
    return httpClient.post<Alarm[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/alarm-list.php`, form);
}

export const fetchAlarmList = ({ key, stationCodes }: { key: string, stationCodes: string }): Promise<Alarm[]> => {
    return getAlarmList(stationCodes).then(res => res.data);
}

export const fetchRealtimeDeviceProducePower = ({ key, deviceList }: { key: string, deviceList: Device[] }): Promise<ResidentDeviceProducePower> => {
    return getRealtimeDeviceProducePower(deviceList).then(res => res.data);
}

export const fetchPowerProduce = ({ key, deviceList, timeSeries }: { key: string, deviceList: Device[], timeSeries: string }): Promise<PowerProduce> => {
    const solarSnsList = deviceList.filter(d => d.devTypeId === 1).map(d => d.esnCode);
    return getPowerProduce(solarSnsList, timeSeries).then(res => res.data);
}

export const fetchDevicesProducePower = ({ key, deviceList, timeSeries }: { key: string, deviceList: Device[], timeSeries: string }): Promise<DeviceProducePower[]> => {
    const solarSnsList = deviceList.filter(d => d.devTypeId === 1).map(d => d.esnCode);

    return getDeviceProducePower(solarSnsList, timeSeries).then(res => {
        const deviceProducePowerList: DeviceProducePower[] = [];
        solarSnsList.forEach(esnCode => {
            const deviceInfoList = res.data.filter(device => device.sn === esnCode);

            if (deviceInfoList && deviceInfoList.length > 0) {
                const dataItem = deviceInfoList.map(d => d.dataItemMap);

                deviceProducePowerList.push({
                    sn: esnCode,
                    installedCapacity: deviceInfoList[0].dataItemMap.installedCapacity,
                    perpowerRatio: dataItem.reduce((acc, current) => acc + current.perpowerRatio, 0),
                    productPower: dataItem.reduce((acc, current) => acc + current.productPower, 0),
                });
            }
        });
        return deviceProducePowerList;
    });
}