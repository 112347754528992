import { Layout} from 'antd';
import { Outlet } from 'react-router-dom';
const { Content, Footer } = Layout;

const AnonymousLayout: React.FC = () => {

    return (
        <Layout className='app-layout'>
            <Content
                style={{
                    padding: 20,
                }}
            >
                <Outlet />
            </Content>
            <Footer style={{ textAlign: 'center' }}>Sirichai FusionSolar Mornitoring ©2023 Sirichai Electric All rights RESERVED.</Footer>
        </Layout>
    );
};

export default AnonymousLayout;
