import { AxiosResponse } from 'axios';
import { Plant, PowerUsageData, YearlyPowerUsageData, RealtimePlantData } from '../types/PlantData';
import { httpClient } from '../utils/HttpClient';

const getPowerUsage = (stationCodes: string, timeSeries: string, timestamp: number): Promise<AxiosResponse<PowerUsageData[]>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        stationCodes,
        timeSeries,
        timestamp
    }));
    return httpClient.post<PowerUsageData[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/power-usage.php`, form);
}

const getPlantInfo = (stationCodes: string): Promise<AxiosResponse<RealtimePlantData[]>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        stationCodes
    }));
    return httpClient.post<RealtimePlantData[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/plant-info.php`, form);
}

const getYearlyPowerUsage = (stationCodes: string): Promise<AxiosResponse<YearlyPowerUsageData[]>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        stationCodes
    }));
    return httpClient.post<YearlyPowerUsageData[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/power-usage-yearly.php`, form);
}

const getPlantList = (): Promise<AxiosResponse<Plant[]>> => {
    const form = new FormData();
    return httpClient.post<Plant[]>(`${process.env.REACT_APP_BACKEND_URL}/controllers/plant-list.php`, form);
}

export const fetchPlantList = ({ key }: { key: string }): Promise<Plant[]> => {
    return getPlantList().then(res => res.data);
}

export const fetchPowerUsage = ({ key, stationCodes, timeSeries, timestamp = 0 }: { key: string, stationCodes: string, timeSeries: string, timestamp: number }): Promise<PowerUsageData[]> => {
    return getPowerUsage(stationCodes, timeSeries, timestamp).then(res => res.data);
}

export const fetchPlantInfo = ({ key, stationCodes }: { key: string, stationCodes: string }): Promise<RealtimePlantData[]> => {
    return getPlantInfo(stationCodes).then(res => res.data);
}

export const fetchYearlyPowerUsage = ({ key, stationCodes }: { key: string, stationCodes: string }): Promise<YearlyPowerUsageData[]> => {
    return getYearlyPowerUsage(stationCodes).then(res => res.data);
}
