import { useEffect } from 'react';
import { Layout } from 'antd';
import useSWR from 'swr';
import { Outlet } from 'react-router-dom';
import { usePlantContext } from './contexts/PlantContext';
import { fetchDeviceList } from './services/DeviceService';
import { reLogin } from './services/AccessControlService';
import { fetchPlantList } from './services/PlantService';
const { Content } = Layout;

type Props = {};

const AutoLoginLayout: React.FC<Props> = (props: Props) => {
    const TEN_MINUTES = 600000;
    const { plant, setPlant, setDevices } = usePlantContext();

    const { data: devices } = useSWR(
        plant?.plantCode ? { key: 'DeviceList', stationCodes: plant.plantCode } : null,
        fetchDeviceList,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
        }
    );

    const { data: plants } = useSWR(
        { key: 'PlantList' },
        fetchPlantList,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
        }
    );

    useEffect(() => {
        if (devices) {
            setDevices(devices);
        }
    }, [devices, setDevices])

    useEffect(() => {
        if (plants) {
            if (plants.length > 0) {
                setPlant(plants[0]);
            }
        }
    }, [plants, setPlant])

    useEffect(() => {
        const interval = setInterval(async () => {
            await reLogin();
        }, TEN_MINUTES);

        return () => clearInterval(interval);
    }, [])

    return (
        <Layout className='app-layout'>
            <Content
                style={{
                    padding: 20,
                }}
            >
                <Outlet />
            </Content>
        </Layout>
    );
};

export default AutoLoginLayout;
