import { AxiosResponse } from 'axios';
import { LoginForm } from '../types/AccessControl';
import { httpClient } from '../utils/HttpClient';

export const signIn = (loginForm: LoginForm): Promise<AxiosResponse<{}>> => {
    const form = new FormData();
    form.append('data', JSON.stringify({
        ...loginForm
    }));
    return httpClient.post<{}>(`${process.env.REACT_APP_BACKEND_URL}/controllers/sign-in.php`, form);
}

export const reLogin = (): Promise<AxiosResponse<{}>> => {
    return httpClient.put<{}>(`${process.env.REACT_APP_BACKEND_URL}/controllers/re-login.php`);
}