import { ReactNode, createContext, useContext, useState } from "react";
import { Plant } from "../types/PlantData";
import { Device } from "../types/DeviceData";

export type PlantStore = {
    plant: Plant | null;
    devices: Device[];
    setPlant: (plant: Plant) => void;
    setDevices: (devices: Device[]) => void;
}

const PlantContext = createContext<PlantStore>({
    plant: null,
    devices: [],
    setPlant: () => { },
    setDevices: () => { },
});

export function usePlantContext() {
    return useContext(PlantContext);
}

type Props = {
    children?: ReactNode;
}

export const PlantProvider = (props: Props) => {
    const [plant, setPlant] = useState<Plant | null>(null);
    const [devices, setDevices] = useState<Device[]>([]);

    const plantStore = {
        plant,
        devices,
        setPlant,
        setDevices,
    }

    return <PlantContext.Provider value={plantStore}>{props.children}</PlantContext.Provider>
}

export function withPlantContext<T>(
    WrappedComponent: React.ComponentType<T>
) {
    // Try to create a nice displayName for React Dev Tools.
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || "Component";

    // Creating the inner component. The calculated Props type here is the where the magic happens.
    const ComponentWithPlantContext = (props: Omit<T, keyof PlantStore>) => {
        return <PlantContext.Consumer>
            {contextValue => <WrappedComponent {...contextValue} {...(props as T)} />}
        </PlantContext.Consumer>;
    };

    ComponentWithPlantContext.displayName = `withPlantContext(${displayName})`;

    return ComponentWithPlantContext;
}