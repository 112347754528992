import './BaseLayout.css'
import { useEffect, useState } from 'react';
import { Col, Layout, Menu, MenuProps, Row } from 'antd';
import useSWR from 'swr';
import { Outlet } from 'react-router-dom';
import { HomeTwoTone } from '@ant-design/icons';
import { usePlantContext } from './contexts/PlantContext';
import { Plant } from './types/PlantData';
import { fetchDeviceList } from './services/DeviceService';
import { reLogin } from './services/AccessControlService';
import { fetchPlantList } from './services/PlantService';
const { Header, Content, Footer } = Layout;

type Props = { items: any[] };
type State = {
    plantList: Plant[],
    plantItems: any;
}

const BaseLayout: React.FC<Props> = (props: Props) => {
    const TEN_MINUTES = 600000;
    const { plant, setPlant, setDevices } = usePlantContext();
    const [plantData, setPlantData] = useState<State>({ plantList: [], plantItems: [] });

    const { data: devices } = useSWR(
        plant?.plantCode ? { key: 'DeviceList', stationCodes: plant.plantCode } : null,
        fetchDeviceList,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
        }
    );

    const { data: plants } = useSWR(
        { key: 'PlantList' },
        fetchPlantList,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
        }
    );

    useEffect(() => {
        if (devices) {
            setDevices(devices);
        }
    }, [devices, setDevices])

    useEffect(() => {
        if (plants) {
            setPlantData({
                plantList: plants,
                plantItems: plants.map(plant => ({
                    key: plant.plantCode,
                    icon: <HomeTwoTone />,
                    label: plant.plantName,
                })),
            });

            if (plants.length > 0) {
                setPlant(plants[0]);
            }
        }
    }, [plants, setPlant])

    useEffect(() => {
        const interval = setInterval(async () => {
            await reLogin();
        }, TEN_MINUTES);

        return () => clearInterval(interval);
    }, [])

    const updatePlantInformation = async (key: string) => {
        const plant = plantData.plantList.find(plant => plant.plantCode === key);

        if (plant) {
            setPlant(plant);
        }
    }

    const onMenuItemClicked: MenuProps['onClick'] = (e) => {
        updatePlantInformation(e.key);
    };

    return (
        <Layout className='app-layout'>
            <Header style={{ display: 'flex', alignItems: 'center' }}>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    items={props.items}
                    style={{ flex: 1, minWidth: 0 }}
                />
            </Header>
            <Content
                style={{
                    padding: 16,
                }}
            >
                <Row className='layout-row' gutter={[16, 16]}>
                    <Col span={3}>
                        {
                            plantData && plantData.plantItems && plantData.plantItems.length > 0 ?
                                <Menu
                                    theme="dark"
                                    className="menu-left"
                                    onClick={onMenuItemClicked}
                                    mode="inline"
                                    items={plantData.plantItems}
                                    defaultSelectedKeys={[plantData.plantItems[0]['key']] || []}
                                /> : null
                        }

                    </Col>
                    <Col span={21}>
                        <Outlet />
                    </Col>
                </Row>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Sirichai FusionSolar Mornitoring ©2023 Sirichai Electric All rights RESERVED.</Footer>
        </Layout>
    );
};

export default BaseLayout;
