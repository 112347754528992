import './SignIn.css';
import React, { useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Typography } from 'antd';
import { signIn } from '../../services/AccessControlService';
import { LoginForm } from '../../types/AccessControl';

const { Title } = Typography;

const SignIn: React.FC = () => {
    const [form] = Form.useForm();
    const [message, setMessage] = useState('');
    const { mutate } = useSWRConfig();

    useEffect(() => {
        mutate(
            key => true, // which cache keys are updated
            undefined, // update cache data to `undefined`
            { revalidate: false } // do not revalidate
        )
    }, [mutate]);

    const onFinish = async (values: LoginForm) => {
        try {
            const response = await signIn(values);
            if (response.status === 200) {
                window.location.replace(application.webRoot);
            }
        } catch (error) {
            setMessage('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาทำรายการใหม่');
            form.resetFields();
        }
    }

    return (
        <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item>
                <Title level={1} className='login-header'>เข้าสู่ระบบ</Title>
            </Form.Item>
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'กรุณาระบุชื่อผู้ใช้งาน' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="ชื่อผู้ใช้งาน" size="large" autoFocus={true} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'กรุณาระบุรหัสผ่าน' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="รหัสผ่าน"
                    size="large"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" size='large'>
                    เข้าสู่ระบบ
                </Button>
            </Form.Item>
            {message ? <Alert
                message="พบข้อผิดพลาด"
                description={message}
                type="warning"
            /> : null}
        </Form>
    );
};

export default SignIn;